import React from "react"
import Viewport from "../../components/common/viewport"
import CareerContent from "../content/career/career-content"
import SEO from "../seo"

const CareerPage = props => {
  const page = props.pageContext.careerPage

  if (!page) return

  return (
    <Viewport>
      <CareerContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default CareerPage

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.careerPage.seoTitle}
    description={pageContext.careerPage.seoDescription}
    keywords={pageContext.careerPage.seoKeywords}
  />
)
