import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Section from "../../common/section"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  margin-bottom: 0;
`

const CompanyAdvantages = styled.div`
  padding: 15rem 0;

  ol {
    list-style-type: none;
  }

  li {
    position: relative;
    color: ${props => props.theme.colors.text2};
    padding: 5rem 0;
    border-bottom: 0.3rem solid ${props => props.theme.colors.invertedText};

    &:first-of-type {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    > div {
      position: relative;

      @media (min-width: ${props => props.theme.devices.desktop}) {
        padding-left: 11rem;

        > span {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(0, -50%, 0);
          margin-top: 0.4rem;
        }
      }
    }
  }

  p {
    @media (min-width: ${props => props.theme.devices.desktop}) {
      display: flex;
      align-items: center;
      text-align: right;
    }
  }

  b {
    white-space: nowrap;
    color: ${props => props.theme.colors.invertedText};
    text-align: left;
    max-width: 67rem;
    margin-right: auto;
    font-size: 3.8rem;
    line-height: 4rem;
    padding-right: 3rem;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 4.8rem;
      line-height: 5rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 8rem;
      line-height: 8rem;
    }
  }
`

const options = {
  renderNode: {
    [BLOCKS.OL_LIST]: (node, children) => {
      if (!children) return

      return (
        <ol>
          {children.map((child, index) => (
            <li key={index}>
              <div>
                <span>{index < 10 ? `0${index + 1}` : `${index + 1}`}.</span>
                {child.props.children}
              </div>
            </li>
          ))}
        </ol>
      )
    },
  },
}

const CareerInfoSection = ({ career }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      invertedTextColor={Theme.colors.invertedText}
      threshold={0.4}
      sectionId="career-info-section"
      sectionColor={Theme.colors.text}
    >
      <CompanyAdvantages>
        {documentToReactComponents(
          JSON.parse(career.companyAdvantages.raw),
          options,
        )}
      </CompanyAdvantages>
    </StyledSection>
  )
}

CareerInfoSection.propTypes = {
  careerData: PropTypes.object,
}

export default CareerInfoSection
