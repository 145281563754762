import React from "react"
import PropTypes from "prop-types"
import IntroSection from "./intro-section"
import JobOffersSection from "./job-offers-section"
import CareerInfoSection from "./career-info-section"
import ContactSection from "../../common/contact/contact-section"
import Theme from "../../../theme/theme"

const CareerContent = ({ pageContext }) => {
  return (
    <>
      <IntroSection career={pageContext.careerPage} />
      <CareerInfoSection career={pageContext.careerPage} />
      <JobOffersSection
        offers={pageContext.careerPage.jobOffers}
        offersPageSlug={pageContext.careerPage.slug}
      />
      {pageContext.careerPage.contact && (
        <ContactSection
          content={pageContext.careerPage.contact}
          sectionColor={Theme.colors.gray2}
          contactPageSlug={pageContext.contactPageSlug}
        />
      )}
    </>
  )
}

CareerContent.propTypes = {
  pageContext: PropTypes.object,
}

export default CareerContent
