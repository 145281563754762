import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import Theme from "../../../theme/theme"
import ScrollButton from "../../common/scroll-button"
import GatsbyImage from "../../common/gatsby-image"
import Shape from "../../svg/shape"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
`

const IntroText = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  padding-top: 11rem;
  z-index: 1;
  font-size: 2.8rem;
  line-height: 3.2rem;

  > div {
    padding-top: 2rem;
  }

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;

    > div {
      max-width: 86rem;
      text-align: right;
      margin-left: auto;
      padding-top: 7rem;
    }
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    padding-bottom: 11rem;

    > div {
      padding-top: 0;
    }
  }
`

const H1 = styled.h1`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 4rem;
  line-height: 4.5rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 8rem;
    line-height: 8rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 10rem;
    line-height: 12rem;
  }
`

const StyledScrollButton = styled(ScrollButton)`
  position: relative;
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-family: "darker_grotesquebold", sans-serif;
  text-align: left;
  color: inherit;
  background: none;
  border: 0;
  margin-top: 3rem;
  padding-right: 12rem;
  cursor: pointer;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-top: 10rem;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -4rem;
  }
`

const ImagesRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;
    padding: 18rem 0;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  overflow: hidden;
  width: 100%;
  margin-bottom: 5rem;
  transform: translateZ(0);

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-bottom: 0;
  }

  &.intro-image0 {
    max-width: 100%;
    border-radius: 0 0 0 14rem;
    margin-top: 8rem;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      position: absolute;
      top: 100%;
      left: 50%;
      max-width: 45rem;
      margin-top: 0;
    }
  }

  &.intro-image1 {
    position: relative;
    max-width: 100%;
    border-radius: 0 31rem 0 0;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      left: -5rem;
      top: 5rem;
      max-width: 120rem;
    }
  }

  &.intro-image2 {
    position: relative;
    margin-left: auto;
    border-radius: 31rem 0 0 0;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      top: -10rem;
      right: -5rem;
      max-width: 61rem;
    }
  }

  &.intro-image3 {
    position: relative;
    max-width: 100%;
    border-radius: 0 0 13rem 0;
    z-index: 5;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      max-width: 50rem;
      margin: 0 auto -5rem 50%;
    }
  }
`

const StyledShape1 = styled(Shape)`
  position: absolute;
  top: 11rem;
  left: 30%;
  transform: rotate(-90deg);
  z-index: -1;
`

const StyledShape2 = styled(Shape)`
  position: absolute;
  top: 20%;
  right: 10%;
  width: 37rem;
  transform: rotate(-90deg);
  z-index: 1;
`

const StyledShape3 = styled(Shape)`
  position: absolute;
  bottom: 0;
  left: 20%;
  transform: rotate(180deg);
`

const StyledShape4 = styled(Shape)`
  position: absolute;
  top: 10%;
  right: 0;
  z-index: -1;
`

const IntroSection = ({ career }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      invertedTextColor={Theme.colors.text}
      threshold={0.4}
    >
      <IntroText>
        <H1>
          {career.header}
          <StyledScrollButton sectionId="career-info-section" />
          {career.introImages[0] && (
            <StyledGatsbyImage
              image={career.introImages[0].gatsbyImageData}
              className={`intro-image0`}
            />
          )}
        </H1>
        <div>{documentToReactComponents(JSON.parse(career.introText.raw))}</div>
      </IntroText>

      <ImagesRow>
        {career.introImages[1] && (
          <StyledGatsbyImage
            image={career.introImages[1].gatsbyImageData}
            className={`intro-image1`}
          />
        )}
        {career.introImages[2] && (
          <StyledGatsbyImage
            image={career.introImages[2].gatsbyImageData}
            className={`intro-image2`}
          />
        )}
        <StyledShape2 isSecondColor={true} />
        <StyledShape3 isMainColor={true} />
      </ImagesRow>
      {career.introImages[3] && (
        <StyledGatsbyImage
          image={career.introImages[3].gatsbyImageData}
          className={`intro-image3`}
        />
      )}

      <StyledShape1 isMainColor={true} />
      <StyledShape4 isSecondColor={true} />
    </StyledSection>
  )
}

IntroSection.propTypes = {
  careerData: PropTypes.object,
}

export default IntroSection
